import { Injectable } from "@angular/core";
import { FormControl, ValidatorFn, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root"
})
export class UiHelpersService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  autoCompleteFilter(
    control: FormControl,
    source: string[]
  ): Observable<string[]> {
    return control.valueChanges.pipe(
      startWith(""),
      map(value => {
        const filterValue = value.toLowerCase();

        return source.filter(option =>
          option.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  ssmlValidators(): ValidatorFn[] {
    return [
      Validators.pattern(/^<speak>([\s\S])*<\/speak>$|^arn:([\s\S])*$/),
      Validators.maxLength(1024)
    ];
  }

  phoneValidator(): ValidatorFn {
    return Validators.pattern(/^([0-9]){10}$/);
  }

  getQueues(): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.backendUrl}/helpers/queues`);
  }

  getTimezones(): Observable<string[]> { 
    return this.http.get<string[]>(`${this.config.backendUrl}/helpers/timezones`);
   }
}
