import { Injectable } from '@angular/core';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  constructor(private config: ConfigService) {
  }

  isUserSignedIn() {
    return sessionStorage.getItem('cognito_token') && sessionStorage.getItem('cognito_token').length > 0;
  }

  getAuthToken() {
    return sessionStorage.getItem('cognito_token');
  }

  setAuthToken(token) {
    sessionStorage.setItem('cognito_token', token);
  }

  redirectToSignIn() {
    window.location.href = `https://${
      this.config.cognito.domain
      }.auth.${this.config.region}.amazoncognito.com/login?response_type=${
        this.config.cognito.responseType
      }&client_id=${this.config.cognito.clientId}&redirect_uri=${
        this.config.cognito.redirectUri
      }`;
  }
}
