import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import Holiday from "../models/holiday";
import ContactFlow from "../models/contactFlow";
import * as moment from "moment";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root"
})
export class HolidayService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getHolidays(): Observable<Holiday[]> {
    return this.http.get<Holiday[]>(`${this.config.backendUrl}/holidays`);
  }

  // Holiday Prompts are stored as a specialized "Contact Flow", this does not represent an actual flow from a Connect perspective
  getHolidayPrompts(): Observable<ContactFlow> {
    return this.http.get<ContactFlow>(
      `${this.config.backendUrl}/holidays/prompts`
    );
  }

  saveHoliday(holiday: Holiday): Observable<Holiday> {
    return this.http.put<Holiday>(
      `${this.config.backendUrl}/holidays`,
      holiday
    );
  }

  deleteHoliday(id: string): Observable<any> {
    return this.http.delete<Holiday>(
      `${this.config.backendUrl}/holidays/${id}`
    );
  }

  createHoliday(name: string): Observable<Holiday> {
    const today = new Date();
    return this.http.post<Holiday>(`${this.config.backendUrl}/holidays`, {
      name: name,
      allDay: true,
      date: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "00:00",
      queues: [],
      timezone: "EST"
    } as Holiday);
  }
}
