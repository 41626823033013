import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from "./config.service";
import { ListPromptsResponse } from '../models/connectResponses';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  constructor(
    private config: ConfigService,
    private http: HttpClient
    ) {}

  listPrompts(): Observable<ListPromptsResponse> {
    return this.http.get<ListPromptsResponse>(`${this.config.backendUrl}/connect/listPrompts`);
  }
}
