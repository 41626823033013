import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { EditPromptModule } from '../edit-prompt/edit-prompt.module';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { PlaySsmlModule } from '../play-ssml/play-ssml.module';
import { PromptTableComponent } from './prompt-table.component';

@NgModule({
  declarations: [PromptTableComponent],
  exports: [PromptTableComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatCheckboxModule,
    PlaySsmlModule,
    MatTableModule,
    MatSortModule,
    EditPromptModule,
    MatProgressSpinnerModule
  ]
})
export class PromptTableModule { }
