import { ListPromptsResponse, ConnectInstancePromptSummary } from './../../models/connectResponses';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter  } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Prompt, PromptData } from 'src/app/models/contactFlow';
import { MatDialog } from '@angular/material';
import { EditPromptComponent } from '../edit-prompt/edit-prompt.component';
import { ContactFlowsService } from 'src/app/services/contact-flows.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { languageData } from 'src/app/data/languageData';
import PromptDialogData from 'src/app/models/promptDialog';
import { ConnectService } from './../../services/connect.service';

@Component({
  selector: 'app-prompt-table',
  templateUrl: './prompt-table.component.html',
  styleUrls: ['./prompt-table.component.scss']
})
export class PromptTableComponent implements OnInit {

  @Input() prompt: Prompt;
  @Input() flowName: string;
  @Input() allowDisabledPrompts = false;

  displayedColumns = ['lang', 'value', 'name', 'edit'];
  promptData = [];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  prompts: any;
  connectInstancePrompts: ConnectInstancePromptSummary[];

  constructor(
    public dialog: MatDialog,
    private contactFlowsService: ContactFlowsService,
    private snackbarService: SnackbarService,
    private connectService: ConnectService
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    if (this.prompt) {
      this.dataSource.data = this.prompt.data;
    }

    this.connectService.listPrompts().subscribe((prompts: ListPromptsResponse) => {
      this.connectInstancePrompts = prompts.PromptSummaryList;
      this.mapPromptNames();
    }, (error: any) => {
      error.customMessage = 'Error fetching data from Connect';
      this.mapPromptNames(error);
      console.error('Error listing prompts from connect instance:', error);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayLang(code: string) {
    const resp = languageData.find(element => element.code === code);
    return resp ? resp.name : code;
  }

  mapPromptNames(error = null) {
    const connectPromptArns: string[] = this.connectInstancePrompts.map((prompt: ConnectInstancePromptSummary) => prompt.Arn);

    this.dataSource.data = this.dataSource.data.map((tablePrompt: PromptData) => {
      const adminTableInput: string = tablePrompt.value.toLowerCase().trim();
      if (
          adminTableInput.startsWith('arn:') &&
          connectPromptArns.includes(adminTableInput)
        ) {
        const promptName: string = this.connectInstancePrompts.find((prompt: ConnectInstancePromptSummary) => {
          return prompt.Arn === adminTableInput;
        }).Name;
        tablePrompt.name = promptName;
      } else {
        tablePrompt.name = adminTableInput.startsWith('arn:') ? 'Not found in Connect' : '';
      }

      if (error) {
        tablePrompt.name = error.customMessage;
      }

      return tablePrompt;
    });
  }

  openEditor(element: {lang: string, value: string, name?: string}, mode: 'add' | 'edit') {

    const dialogData: PromptDialogData = {
      prompt: this.prompt,
      lang: element.lang,
      value: element.value,
      mode: mode,
      name: name
    };

    const dialogRef = this.dialog.open(EditPromptComponent, {
      width: '80vw',
      data: JSON.parse(JSON.stringify(dialogData))
    });

    dialogRef.afterClosed().subscribe((result) => {
      return this.dialogCloseHandler(result);
    });
  }

  async dialogCloseHandler(result: { data: PromptDialogData, action: 'save' | 'add' | 'delete' }) {
    if (result && result.data) {
      let promptIndex = result.data.prompt.data.length + 1;
      if (result.data.mode !== 'add') {
        promptIndex = result.data.prompt.data.findIndex(prompt => prompt.lang === result.data.lang);
      }
      switch (result.action) {
        case 'save':
          if (result.data.mode === 'add') {
            result.data.prompt.data.push({lang: result.data.lang, value: result.data.value });
          } else {
            result.data.prompt.data[promptIndex].value = result.data.value;
          }
          this.updatePrompt(result.data.prompt);
          return 'saved';
        case 'delete':
          if (confirm(`This will remove this prompt from the database. Are you sure you want to continue?`)) {
            result.data.prompt.data.splice(promptIndex,1);
            this.updatePrompt(result.data.prompt);
            return 'deleted';
          }
          return 'delete cancelled';
      }
    } else {
      return 'cancelled';
    }
  }

  async updatePrompt(prompt: Prompt, success?: string, errMsg?: string) {

    this.contactFlowsService
      .savePrompt(prompt, this.flowName)
      .subscribe(
        res => {
          this.prompt = prompt;
          this.dataSource.data = this.prompt.data;
          this.mapPromptNames();
          this.snackbarService.success(success || 'Success');
        },
        err => {
          console.error('Error updating prompt: ', err);
          err.customMessage = 'Error updating prompt';
          this.mapPromptNames(err);
          this.snackbarService.error(errMsg || 'Error updating prompt');
        }
      );
  }

  setDisabled() {
    this.updatePrompt(this.prompt, `Disabled set to ${this.prompt.disabled}`);
  }


}
